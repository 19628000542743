import $ from 'jquery';
// const $ = require('jquery');
global.$ = global.jQuery = $;
window.$ = window.jQuery = $;
import "bootstrap/dist/js/bootstrap.bundle";
import "jquery-toast-plugin";

(function($) {

    const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/))
    if(isMobile){
        $('body').addClass('mobile');
    } else {
        $('body').addClass('desktop');
    }

    // Pretty checkbox
    $(".form-check label input, .form-radio label input").after('<i class="input-helper"></i>');

    function showToastAlerts($alerts) {

        const loaderColors = {
            'success': '#f96868',
            'info': '#46c35f',
            'warning': '#57c7d4',
            'error': '#f2a654',

        }

        $alerts.each((i, alert)=>{
            let $alert = $(alert);
            $.toast({
    //          heading: $alert.data('alert-type') ,
              text: $alert.text(),
              showHideTransition: 'slide',
              icon: $alert.data('alert-type'),
              loaderBg: loaderColors[$alert.data('alert-type')],
              position: 'top-right',
              hideAfter: 10000
            });
        });

    }
    showToastAlerts($('.toast-alert'));

})(jQuery);